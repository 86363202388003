<template>
<div class="page-reporte-lista-precios">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Reporte de Ventas Excentas/Gravadas" class="elevation-1 px-5 py-3">

          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md>
                <v-row>
                  <v-col sm="4" md="4" lg="4">
                      <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event"
                            @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu1 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                  </v-col>
                  <v-col sm="4" md="4" lg="4">
                      <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event"
                            @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu2 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="model = clean_model()">
              <v-icon>cancel</v-icon> Limpiar Filtros
            </v-btn>
            <v-btn color="success" @click.native="generar_reporte()">
              <v-icon>done</v-icon> {{ "Generar Reporte" }}
            </v-btn>
          </v-card-actions>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  components: {},
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
  },
  mounted: function() {
    if (!this.verificaPermiso('t.facturas.excentas')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
  },
  data() {
    return {
      menu1:false,
      menu2:false,
      sucursales: [],
      search_articulo: '',
      search_marca: null,
      categorias: [],
      marcas: [],
      articulos: [],
      articulos_proveedores: [],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Ventas Excentas/Gravadas",
          disabled: true,
          href: ""
        }
      ],
      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    clean_model: function() {
      return {
        fecha_de:'',
        fecha_a:''
      };
    },
    generar_reporte: function() {
      try {
        if(!this.$refs.form.validate()){return;}
        window.dialogLoader.show('Espere un momento por favor..');

        if (this.model.id_articulo == null)
          this.model.id_articulo = '';

        window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_excentas_gravadas/", this.model, {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Allow": "POST",
            "cache-control": "no-cache",
            "responseType": "arraybuffer"
          })
          .then(response => {
            var bytes = response.data;
            let blob = new Blob([bytes], {
                type: response.headers["content-type"]
              }),
              url = window.URL.createObjectURL(blob);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "Ventas_Excentas_Gravadas.xlsx";
            a.click();
            a.parentNode.removeChild(a);
          })
          .catch(error => {
            console.log(error);
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      } catch (error) {
        console.log("ERRORS: ", error);
      }
    },



  }
}
</script>
